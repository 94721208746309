<template>
  <div class="page history-page">
    <Header />
    <div class="container" style="margin-top: 0; margin-bottom: auto;">
      <div class="mb--sm--3 mt--sm--3 mt--md--9">
        <ChooseCustomer label="Chọn khách hàng để xem" buttonText="Chọn" v-on:click="changeCustomerHandle" noButton/>
      </div>
      <div class="h5 font-weight--sm--500 mb--sm--3 d--sm--flex mt--sm--3 mt--md--9">
        <HistoryIcon class="mr--sm--2"/>
        <span>Lịch sử đơn hàng</span>
      </div>
      <el-tabs v-model="tabCurrentActive">
        <el-tab-pane label="Chờ xác nhận" name="first" class="history-scroll">
          <OrderHistoryCard v-for="order in getOrderByStatus(['Z1', 'Z3'])" :key="order.orderID" :order="order"/>
        </el-tab-pane>
        <el-tab-pane label="Đã xác nhận" name="second" class="history-scroll">
          <OrderHistoryCard v-for="order in getOrderByStatus(['Z2'])" :key="order.orderID" :order="order"/>
        </el-tab-pane>
        <el-tab-pane label="Đang giao" name="third" class="history-scroll">
          <OrderHistoryCard v-for="order in getOrderByStatus(['Z4'])" :key="order.orderID" :order="order"/>
        </el-tab-pane>
        <el-tab-pane label="Đã giao" name="fourth" class="history-scroll">
          <OrderHistoryCard v-for="order in getOrderByStatus(['Z5'])" :key="order.orderID" :order="order"/>
        </el-tab-pane>
        <el-tab-pane label="Đã huỷ" name="fifth" class="history-scroll">
          <OrderHistoryCard v-for="order in getOrderByStatus(['Z6', 'Z7'])" :key="order.orderID" :order="order"/>
        </el-tab-pane>
      </el-tabs>
    </div>
    <Footer/>
    <FooterMobile/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import OrderHistoryCard from '@/components/OrderHistoryCard'
import HistoryIcon from '@/assets/history-icon.svg'
import ChooseCustomer from '@/components/ChooseCustomer.vue'

export default {
  components: {
    Header,
    Footer,
    FooterMobile,
    OrderHistoryCard,
    HistoryIcon,
    ChooseCustomer
  },
  data () {
    return {
      tabCurrentActive: 'first'
    }
  },
  methods: {
    changeCustomerHandle () {
      this.$store.dispatch('customer/getOrders', {
        customerCode: this.$store.getters['auth/getToken']
      })
    },
    getOrderByStatus (status) {
      return this.$store.getters['customer/getOrderByStatus'](status)
    }
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: false,
      headingText: '',
      back: true,
      search: true,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', false)

    this.$store.dispatch('customer/getOrders', {
      customerCode: this.$store.getters['auth/getToken']
    })
  },
  computed: {
    orders () {
      return this.$store.state.customer.orders
    }
  }
}
</script>

<style lang="scss">
  .history-scroll {
    max-height: 500px;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
</style>
