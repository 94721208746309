<template>
    <div v-if="$store.state.auth.user.partnrType !== 0" class="mb--sm--5 mb--md--9">
        <div class="h5 font-weight--500 mb--sm--3 mb--md--6" v-if="label">{{label}}</div>
        <div class="row">
          <div class="col" :class="[noButton ? 'col--sm--12' : 'col--sm--8 col--md--9']">
            <HdButton type="selected-box" v-on:click="dialogVisible = true" fullWidth>{{ (noButton) ? (customerSelected && customerSelected.name) ? customerSelected.name : 'Chọn khách hàng' :  (customerSelectedLocal && customerSelectedLocal.name) ? customerSelectedLocal.name : 'Chọn khách hàng'}}
              <template v-slot:icon><ArrowDownIcon/></template>
            </HdButton>
          </div>
          <div class="col col--sm--4 col--md--3" v-if="!noButton">
            <HdButton v-on:click="confirmClickHandle(customerSelectedLocal)" fullWidth>
              {{buttonText}}
              <template v-slot:icon><CheckIcon/></template>
            </HdButton>
          </div>
        </div>
        <el-dialog
        title="Chọn khách hàng"
        :visible.sync="dialogVisible">
        <div>
          <HdInput
                  v-model="search"
                  type="text"
                  placeholder="Tìm kiếm"
                  v-on:keyup="searchOnChangeHandle"
                >
                  <template v-slot:icon><FinderIcon/></template>
                </HdInput>
          <div v-for="custommer in custommers" :key="custommer.customerID" class="customer" :class="[noButton ? customerSelected.customerID === custommer.customerID ? 'customer--is-selected' : '' : customerSelectedLocal.customerID === custommer.customerID ? 'customer--is-selected' : '']" v-on:click="customerClickHandle(custommer)">
              <div v-if="custommer.name" class="customer__name"><span>{{custommer.name}}</span><div v-if="custommer.customerID" class="customer__code">{{custommer.customerID}}</div></div>
              <div v-if="custommer.address" class="customer__address">{{custommer.address}}</div>
              <CheckIcon class="customer__check-icon" v-if="noButton ? customerSelected.customerID === custommer.customerID : customerSelectedLocal.customerID === custommer.customerID"/>
          </div>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import _ from 'lodash'
// Import Components
import HdButton from '@/components/HdButton'
import CheckIcon from '@/assets/check-icon.svg'
import ArrowDownIcon from '@/assets/arrow-down.svg'
import HdInput from '@/components/HdInput'
import FinderIcon from '@/assets/finder-icon-search-header-mobile.svg'

export default {
  components: {
    HdButton,
    CheckIcon,
    ArrowDownIcon,
    HdInput,
    FinderIcon
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  props: {
    label: String,
    buttonText: String,
    noButton: Boolean
  },
  methods: {
    searchOnChangeHandle: function () {
      let payload = { key: 'tdvID', value: this.$store.state.auth.user.token_id }
      if (this.$store.state.auth.user.partnrType === 2) {
        payload = { key: 'managerID', value: this.$store.state.auth.user.token_id }
      }
      this.$store.dispatch('customer/getCusByManager', payload)
    },
    confirmClickHandle: function (customer) {
      if (!_.isEmpty(customer)) {
        this.$confirm(`Xác nhận đặt hàng cho khách hàng ${customer.name}`, 'Thông báo', {
          confirmButtonText: 'OK'
        }).then(() => {
          this.$store.commit('auth/setCustomerSelected', customer)
          this.$emit('click', customer)
          this.$notify({
            duration: 1000,
            title: 'Thông báo',
            dangerouslyUseHTMLString: true,
            message: `Đã chọn khách hàng <strong style="color: #003B35">${customer.name}</strong>`,
            type: 'success'
          })
        })
      } else {
        this.$alert('Vui lòng chọn khách hàng', 'Thông báo', {
          confirmButtonText: 'OK'
        })
      }
    },
    customerClickHandle: function (customer) {
      if (this.noButton) {
        this.$store.commit('auth/setCustomerSelected', customer)
        this.$emit('click', customer)
      } else {
        this.customerSelectedLocal = customer
      }
      this.dialogVisible = false
    }
  },
  mounted () {
    let payload = { key: 'tdvID', value: this.$store.state.auth.user.token_id }
    if (this.$store.state.auth.user.partnrType === 2) {
      payload = { key: 'managerID', value: this.$store.state.auth.user.token_id }
    }
    this.$store.dispatch('customer/getCusByManager', payload)
    this.customerSelectedLocal = this.customerSelected || {}
  },
  computed: {
    search: {
      get () {
        return this.$store.state.customer.search
      },
      set (customer) {
        this.$store.commit('customer/setSearch', customer)
      }
    },
    customerSelectedLocal: {
      get () {
        return this.$store.state.auth.customerSelectedLocal
      },
      set (customer) {
        this.$store.commit('auth/setCustomerSelectedLocal', customer)
      }
    },
    customerSelected: {
      get () {
        return this.$store.state.auth.customerSelected
      },
      set (customer) {
        this.$store.commit('auth/setCustomerSelected', customer)
      }
    },
    custommers () {
      return this.$store.state.customer.customers
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "@/scss/respoinsive";
    .customer {
      @include mobile() {
        margin: 0 -20px;
      }
      position: relative;
        padding: 16px;
        padding-right: 30px;
      font-weight: 500;
        border-bottom: 1px solid hsl(20, 100%, 36%);
          transition: all 0.3s;
          cursor: pointer;
          color: #003B35;
          font-size: 16px;
          &:hover {
            background-color: #FAE6DA;
          }
          &--is-selected {
            background-color: #FAE6DA;
          }
          &__name {
            display: flex;
            align-items: center;
            font-weight: 500;
            margin-bottom: 4px;
            @include mobile () {
              flex-wrap: wrap;
            }
          }
          &__code {
              position: relative;
              font-weight: 400;
              font-size: 12px;
              color: #B93D00;
              display: flex;
              @include mobile () {
                width: 100%;
                margin-top: 4px;
              }
              &::before {
                 content: "";
                 background-color: #B93D00;
                 width: 7px;
                 height: 7px;
                 border-radius: 50%;
                 display: inline-block;
                 margin-left: 20px;
                 margin-right: 20px;
                 @include mobile () {
                   display: none;
                 }
              }
          }
          &__check-icon {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
          }
    }
</style>
