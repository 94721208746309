<template>
  <div class="order-history-card" :class="{ 'order-history-card--warning': order.status === 'Z3' }" v-if="order">
    <div class="order-history-card__top">
      <div class="order-history-card__left">
        <p><strong>Đơn hàng: </strong></p>
        <p><strong>Ngày đặt hàng: </strong></p>
        <p><strong>Thành tiền: </strong></p>
      </div>
      <div class="order-history-card__right">
        <p v-if="order.orderID"><router-link :to="`/order-history/${order.orderID}`" class="order-history-card__action"><strong>{{order.orderID}}</strong></router-link><span v-if="order.orderNumberSAP && (order.status !== 'Z1' && order.status !== 'Z3')"> - <router-link :to="`/order-history/${order.orderID}`" class="order-history-card__action"><strong>{{order.orderNumberSAP}}</strong></router-link></span></p>
        <p class="font-weight--400" v-if="order.orderDate">{{order.orderDate === '00000000' ? '00/00/0000' : $moment(order.orderDate).format('DD/MM/YYYY')}}</p>
        <p class="font-weight--400" v-if="order.amountAfterTax">{{parseInt(order.amountAfterTax).toLocaleString('vi-VN', {style : 'currency', currency : 'VND'})}}</p>
      </div>
    </div>
    <div class="order-history-card__bottom" v-if="order.status === 'Z3'">
      <WarningIcon class="mr--sm--2"/>
      <span>Đang vướng nợ</span>
    </div>
  </div>
</template>

<script>
import WarningIcon from '@/assets/warning-icon.svg'
export default {
  components: {
    WarningIcon
  },
  props: {
    order: Object
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
  @import "@/scss/respoinsive";
  .order-history-card {
    display: flex;
    flex-wrap: wrap;
    background-color: #CBE0DE;
    padding: 0 8px;
    border-top: solid 2px #BFDDDA;
    min-width: 370px;
    @include tablet() {
      padding: 16px 40px;
    }
    &--warning {
        background-color: #DEDEC1;
    }
    &:first-child {
        border-top: 0;
    }
    &__top {
        display: flex;
        flex: 1 1 100%;
        width: 100%;
        max-width: 100%;
    }
    &__bottom {
        display: flex;
        align-items: center;
        color: #B93D00;
    }
    &__left {
        min-width: 20%;
    }
    &__right {
        flex: 1;
        padding-left: 4px;
    }
  }
</style>
